import React from "react";
import {Helmet} from "react-helmet";
import ArticleListing from "./Article";

function Other(){
    return(
        <div>
            <br /><br />

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Our Gift ideas for Special holidays.</title>
                <link rel="canonical" href="https://specialgiftideas.net/other"/>
                <meta name="description"
                      content="Learn about special holidays and the best gift ideas for these occasions."/>
                <meta name="keywords" content="gift ideas,graduation season"/>
                <meta property="og:title" content="Our Gift ideas for holidays."/>
                <meta property="og:description"
                      content="Learn about holidays and the best gift ideas for these occasions."/>
                <meta property="og:site_name" content="Special holidays"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>

            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Other</h1>
                <ArticleListing category={"other"}></ArticleListing>
            </section>
        </div>
    )
}

export default Other;