import React from "react";
import {Helmet} from "react-helmet";
import ArticleListing from "./Article";

function Hindu(){
    return(
        <div>
            <br /><br />

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Our Gift ideas for Hindu festivals and holidays.</title>
                <link rel="canonical" href="https://specialgiftideas.net/hindu"/>
                <meta name="description"
                      content="Learn about Hindu festivals and the best gift ideas for these occasions."/>
                <meta name="keywords" content="gift ideas,hinduism,hindu festivals,hindu holidays"/>
                <meta property="og:title" content="Our Gift ideas for Hindu festivals and holidays."/>
                <meta property="og:description"
                      content="Learn about Hindu festivals and the best gift ideas for these occasions."/>
                <meta property="og:site_name" content="Hindu festivals"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="article"/>
            </Helmet>


            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Hindu festivals</h1>
            <div className="container px-5 py-1 mx-auto dark:text-white">
                    <p> Hinduism is one of the world's oldest religions, originating in the Indian subcontinent. 
                        It encompasses a wide range of beliefs, practices, and rituals, with a rich tapestry of deities, philosophies, and cultural traditions.
                    </p>
                    <p>
                    The core Principles of Hinduism are: <br />
                    &bull; <b>Dharma</b>  : Duty, righteousness, and living in accordance with the cosmic law.<br />

                    &bull; <b>Karma</b>: The law of cause and effect, where one's actions determine their future experiences.<br />

                    &bull; <b>Samsara</b>: The cycle of birth, death, and rebirth.<br />

                    &bull; <b>Moksha</b>: Liberation from the cycle of samsara and union with the divine.<br />

                    &bull; <b>Yoga</b>: Paths to achieve spiritual insight and tranquility, including Bhakti (devotion), Jnana (knowledge), Karma (action), and Raja (meditation).
                    </p>
                    <p>
                    Hindu holidays, such as Diwali, Holi, and Navratri, play a crucial role in the religious and cultural life of Hindus. 
                    They commemorate significant events in mythology, honor deities, and mark seasonal changes. 
                    These festivals offer opportunities for spiritual renewal, community bonding, and the celebration of cultural heritage.
                    </p>
                    <p>
                    Exchanging gifts during Hindu holidays symbolizes the values of generosity, gratitude, and love. 
                    It fosters a sense of community and strengthens relationships. 
                    Gifts are often given to express goodwill, blessings, and appreciation, reflecting the spirit of the festival and enhancing the joy of the celebrations.
                    </p>
            </div>
                
                <ArticleListing category={"hindu"}></ArticleListing>
            </section>
        </div>
    )
}

export default Hindu;